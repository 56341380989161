import cookie from 'cookie';
import PropTypes from 'prop-types';
import React from 'react';

function UsingSSR({ serverData }) {
  console.log('SSR Page:', serverData);
  return (
    <div>SSR Test</div>
  );
}

UsingSSR.defaultProps = {
  serverData: { message: '' },
};

UsingSSR.propTypes = {
  serverData: { message: PropTypes.string },
};

export default UsingSSR;

export async function getServerData(req) {
  const cookies = cookie.parse(req.headers.get('cookie'));
  return {
    props: {
      nopCommerceId: cookies['.Nop.Customer'],
    },
  };
}
